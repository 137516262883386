import {Route, Switch, Redirect} from 'react-router-dom';
import ForgotPassword from '../pages/ForgotPassword';

function Routes() {
  return (
    <Switch>
      <Route path="/redefine-password" exact component={ForgotPassword} />
      <Route path="/">
        <Redirect to="/redefine-password" />
      </Route>
    </Switch>
  );
}

export default Routes;
