import styled from 'styled-components'
import posed from 'react-pose'

export const Group = styled.div`
  position: relative;

  display: flex;
  flex-direction: column;
  margin-bottom: 10px;

  > label {
    position: absolute;
    top: 10px;
    left: 10px;

    margin-bottom: 5px;

    font-size: 0.75rem;
  }

  > div {
    display: flex;
  }
`

export const Input = styled.div`
  > img {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);

    width: 20px;
    height: 20px;
    opacity: 0.5;

    transition: 0.3s;
    cursor: pointer;

    &:hover {
      opacity: 0.75;
      transition: 0.3s;
    }
  }
`

export const InputAnimated = posed.input({
  init: {
    paddingTop: 0,
    paddingLeft: 0,
    paddingRight: 0
  },

  focus: {
    paddingTop: 20,
    paddingLeft: 10,
    paddingRight: 10
  }
})

export const InputItem = styled(InputAnimated)`
  height: 40px;
  width: 100%;
  border-radius: 6px;
  border: 2px solid ${props => (props.error ? '#f66' : 'transparent')};

  font-size: 0.85rem;
  color: #111;
  background-color: #e5e5e5;

  &:focus,
  &:active {
    outline: none;
  }
`

export const Error = styled.span`
  position: absolute;
  top: -16px;
  right: 4px;

  color: #f66;
  font-size: 0.75rem;
`
