import React, { useState } from 'react';

import EyeOpen from '../../assets/images/eye.svg';
import EyeClosed from '../../assets/images/eye-off.svg';

import {
  Group, Input as InputWrapper, InputItem, Error,
} from './styles';

const Input = ({
  label, error, value, ...props
}: any) => {
  const [isFocus, setFocus] = useState(false);
  const [showPassword, setShowpassword] = useState(false);

  return (
    <Group>
      <label>{label}</label>

      <InputWrapper>
        <InputItem
          pose={isFocus || !!value ? 'focus' : 'init'}
          onFocus={() => setFocus(true)}
          onBlur={() => setFocus(false)}
          error={error}
          type={showPassword ? 'text' : 'password'}
          value={value}
          {...props}
        />

        <img
        alt=""
          src={showPassword ? EyeClosed : EyeOpen}
          onClick={() => setShowpassword(!showPassword)}
        />
      </InputWrapper>

      {error && <Error>{error}</Error>}
    </Group>
  );
};

Input.defaultProps = {
  label: 'Input',
  error: '',
};
export default Input;
