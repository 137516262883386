import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100vh;

  font-family: 'Nunito', sans-serif;
  background-color: #D8B213;
`

export const Logo = styled.img`
  width: 220px;
  margin-bottom: -8px;
`

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  width: 25%;
  padding: 60px 40px 60px;
  margin-top: 40px;
  border-radius: 6px;

  background-color: #fff;

  @media screen and (max-width: 1024px) {
    width: 70%;
  }
`

export const Title = styled.h1`
  margin-bottom: 20px;
  text-align: center;
  font-size: 1.25rem;
`

export const Button = styled.button`
  height: 35px;
  border-radius: 6px;
  opacity: 1;

  color: #fff;
  transition: 0.3s;
  background-color: #FFD112;
  cursor: pointer;

  &:hover {
    transition: 0.3s;
    background-color: #D8B213;
  }

  &:disabled {
    opacity: 0.6;
  }

  &:focus,
  &:active {
    outline: none;
  }
`