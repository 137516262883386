/* eslint-disable no-throw-literal */
import React, { useState } from 'react'
import { ToastContainer, toast } from 'react-toastify'
import api from '../../services/api'

import 'react-toastify/dist/ReactToastify.css'

import { Container, Logo, Form, Title, Button } from './styles'

import LogoImage from '../../assets/images/logo.png'

import Input from '../../components/Input'
function useField(initialValue = '') {
    const [value, setValue] = useState(initialValue)
  
    function onChange(e: any) {
      setValue(e.target.value)
    }
  
    return {
      input: {
        onChange,
        value
      }
    }
  }
  
  function parseQueryParams() {
    if (!window.location.search.split('?')[1]) {
      return {}
    }
    return JSON.parse(
      `{"${decodeURI(
        window.location.search
          .split('?')[1]
          .replace(/&/g, '","')
          .replace(/=/g, '":"')
      )}"}`
    )
  }


function ForgotPassword() {
  const [submiting, setSubmiting] = useState(false)
  const newPassword = useField()
  const checkPassword = useField()

  const validate = (token: string) => {
    if (!token) throw { message: 'Nenhum token de recuperação provido' }

    if (newPassword.input.value !== checkPassword.input.value) {
      throw { message: 'As senhas não conferem' }
    }
  }

  const handleSubmit = async (e: any) => {
    try {
      setSubmiting(true)
      e.preventDefault()

      const token = parseQueryParams().token

      validate(token)

      await api.post('/auth/reset', {
        password: newPassword.input.value,
        token
      })

      toast.success('Senha redifinida com sucesso!')
    } catch (err: any) {
      toast.error('Senha inválida! Verifique o número de caracteres e se as senhas conferem.')
    } finally {
      setSubmiting(false)
    }
  }

  return (
    <>
      <Container>
        <Logo src={LogoImage} />

        <Form onSubmit={handleSubmit}>
          <Title>Redefinir senha</Title>

          <Input label="Nova senha" required {...newPassword.input} />
          <Input label="Repita a senha" required {...checkPassword.input} />

          <Button disabled={submiting} type="submit">
            {submiting ? 'Enviando...' : 'Salvar'}
          </Button>
        </Form>

        <ToastContainer autoClose={3000} />
      </Container>
    </>
  )
};

export default ForgotPassword;
